import {
  AppBar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import ThemeCard from "./component/shared/Card";
import { ThemeInput, ThemeSelect } from "./component/shared/Input";
import EuroIcon from "@mui/icons-material/Euro";
import FeesDialog from "./component/core/modal/feesModal";
import { SaveData } from "./service/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./component/core/loading/loader";

function App() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [response, setResponce] = React.useState("");
  const [data, setData] = React.useState({
    company_name: "",
    company_address: "",
    office_expense: "",
    personal_expense: "",
    insurance_expense: "",
    tax_expense: "",
    management_expense: "",
    other_expenses: "",
    home_type: "",
    project_name: "",
    project_address: "",
    built_surface: "",
    home_use: "",
    garage_use: "",
    other_use: "",
    number_homes: "",
    number_floors: "",
    underground: "",
    overground: "",
    num_facades: "",
    topography: "",
    foundation: "",
    structure: "",
    project_hours: "",
    execution_hours: "",
    management_hours: "",
    travel_cost: "",
  });

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    // const isEmpty = Object.values(data).some((value) => value === "");
    // if (isEmpty) {
    //   toast.error("Some fields are empty. Cannot save.");
    //   setLoading(false);
    // } else {
    const result = await SaveData(data);
    if (result.status === true) {
      setResponce(result.data);
      setOpen(true);
      setLoading(false);
      setData({
        company_name: "",
        company_address: "",
        office_expense: "",
        personal_expense: "",
        insurance_expense: "",
        tax_expense: "",
        management_expense: "",
        other_expenses: "",
        home_type: "",
        project_name: "",
        project_address: "",
        built_surface: "",
        home_use: "",
        garage_use: "",
        other_use: "",
        number_homes: "",
        number_floors: "",
        underground: "",
        overground: "",
        num_facades: "",
        topography: "",
        foundation: "",
        structure: "",
        project_hours: "",
        execution_hours: "",
        management_hours: "",
        travel_cost: "",
      });
    } else {
      setLoading(false);
      toast.error(result.error);
    }
    // }
  };

  return (
    <>
      {/* header-------------------- */}
      <AppBar
        position="static"
        style={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <Toolbar
          sx={{ display: "flex", justifyContent: "center", py: 2 }}
          variant="regular"
        >
          <Box
            sx={{
              width: { md: "1140px", xs: "100%" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ height: "30px" }}
              component={"img"}
              src="/images/logo.svg"
              alt="logo"
            />
          </Box>
        </Toolbar>
      </AppBar>

      {/* h6------------------------ */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "80px",
          mb: "20px",
          px: 1,
        }}
      >
        <Box sx={{ maxWidth: { md: "1140px", xs: "100%" } }}>
          <ThemeCard sx={{ mb: 6 }}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography
                  sx={{ fontWeight: 700 }}
                  variant="h6"
                  color="#4211c6"
                  component="div"
                >
                  ¡Hola, arquitectos!
                </Typography>
                <Typography variant="caption">
                  Bienvenidos a nuestra calculadora de honorarios. En esta web,
                  descubriréis cómo valorar vuestro talento arquitectónico.
                  !Vamos a construir juntos!
                </Typography>
              </Grid>
              <Grid
                sx={{ display: { md: "block", xs: "none" } }}
                item
                xs={12}
                md={4}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{ position: "absolute", height: "220px" }}
                    component={"img"}
                    src="/images/3.svg"
                    alt=""
                  />
                </Box>
              </Grid>
            </Grid>
          </ThemeCard>

          <Box>
            <Typography sx={{ fontWeight: 700, mb: 2 }} variant="h5">
              Cálculo de honorarios de arquitecto
            </Typography>

            {/* step1------------------------------------------------ */}
            <ThemeCard sx={{ pb: 6 }}>
              <Typography
                sx={{ fontWeight: 700, mb: 4 }}
                variant="h6"
                color="#4211c6"
                component="div"
              >
                <Box
                  sx={{
                    backgroundColor: "#4211c6",
                    color: "white",
                    borderRadius: "50%",
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    lineHeight: "20px",
                    fontSize: "15px",
                    mr: 1,
                  }}
                  component="span"
                >
                  1
                </Box>
                Información sobre tu estudio
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="text"
                    value={data.company_name}
                    placeholder="Nombre "
                    onChange={(e) =>
                      setData({ ...data, company_name: e.target.value })
                    }
                    label={"Nombre del estudio:"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="text"
                    value={data.company_address}
                    placeholder={"Calle, Número, Población, C.P., Provincia"}
                    onChange={(e) =>
                      setData({ ...data, company_address: e.target.value })
                    }
                    label={"Ubicación:"}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <ThemeInput
                    type="number"
                    value={data.office_expense}
                    onChange={(e) =>
                      setData({ ...data, office_expense: e.target.value })
                    }
                    adornment={<EuroIcon sx={{ fontSize: "14px" }} />}
                    label={"Coste oficina/coworking"}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <ThemeInput
                    type="number"
                    value={data.personal_expense}
                    onChange={(e) =>
                      setData({ ...data, personal_expense: e.target.value })
                    }
                    adornment={<EuroIcon sx={{ fontSize: "14px" }} />}
                    label={"Coste personal/mes"}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <ThemeInput
                    type="number"
                    value={data.insurance_expense}
                    onChange={(e) =>
                      setData({ ...data, insurance_expense: e.target.value })
                    }
                    adornment={<EuroIcon sx={{ fontSize: "14px" }} />}
                    label={"Coste seguro decenal"}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <ThemeInput
                    type="number"
                    value={data.tax_expense}
                    onChange={(e) =>
                      setData({ ...data, tax_expense: e.target.value })
                    }
                    adornment={<EuroIcon sx={{ fontSize: "14px" }} />}
                    label={"Coste autónomo"}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <ThemeInput
                    type="number"
                    value={data.management_expense}
                    onChange={(e) =>
                      setData({ ...data, management_expense: e.target.value })
                    }
                    adornment={<EuroIcon sx={{ fontSize: "14px" }} />}
                    label={"Coste gestoría"}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <ThemeInput
                    type="number"
                    value={data.other_expenses}
                    onChange={(e) =>
                      setData({ ...data, other_expenses: e.target.value })
                    }
                    adornment={<EuroIcon sx={{ fontSize: "14px" }} />}
                    label={"Otros costes"}
                  />
                </Grid>
              </Grid>
            </ThemeCard>

            {/* step2------------------------------------------------ */}
            <Box
              sx={{
                height: 80,
                width: 40,
                borderRight: "2px dashed #4211c6",
              }}
            />
            <ThemeCard sx={{ pb: 6 }}>
              <Typography
                sx={{ fontWeight: 700, mb: 4 }}
                variant="h6"
                color="#4211c6"
                component="div"
              >
                <Box
                  sx={{
                    backgroundColor: "#4211c6",
                    color: "white",
                    borderRadius: "50%",
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    lineHeight: "20px",
                    fontSize: "15px",
                    mr: 1,
                  }}
                  component="span"
                >
                  2
                </Box>
                Información sobre tu proyecto
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="text"
                    value={data.project_name}
                    placeholder={"Nombre"}
                    onChange={(e) =>
                      setData({ ...data, project_name: e.target.value })
                    }
                    label={"Nombre del proyecto:"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="text"
                    placeholder={"Calle, Número, Población, C.P., Provincia"}
                    value={data.project_address}
                    onChange={(e) =>
                      setData({ ...data, project_address: e.target.value })
                    }
                    label={"Ubicación:"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "13px", color: "#000", mb: 0.5 }}>
                    Tipología:
                  </InputLabel>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        mb: 3,
                        "& .MuiChip-root": {
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#4211c6",
                            color: "white",
                          },
                          "&:active": {
                            backgroundColor: "#4211c6",
                            color: "white",
                          },
                        },
                      }}
                    >
                      <Chip
                        sx={
                          data.home_type === 1
                            ? { backgroundColor: "#4211c6", color: "white" }
                            : {}
                        }
                        onClick={() => setData({ ...data, home_type: 1 })}
                        label="Unifamiliar"
                      />
                      <Chip
                        sx={
                          data.home_type === 2
                            ? { backgroundColor: "#4211c6", color: "white" }
                            : {}
                        }
                        onClick={() => setData({ ...data, home_type: 2 })}
                        label="Adosados/Pareados"
                      />
                      <Chip
                        sx={
                          data.home_type === 3
                            ? { backgroundColor: "#4211c6", color: "white" }
                            : {}
                        }
                        onClick={() => setData({ ...data, home_type: 3 })}
                        label="Plurifamiliar"
                      />
                    </Box>

                    <Divider />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    value={data.built_surface}
                    placeholder={"m2"}
                    onChange={(e) =>
                      setData({ ...data, built_surface: e.target.value })
                    }
                    label={"Superficie construida"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    placeholder={"m2"}
                    value={data.home_use}
                    onChange={(e) =>
                      setData({ ...data, home_use: e.target.value })
                    }
                    label={"Uso vivienda"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    value={data.garage_use}
                    placeholder={"m2"}
                    onChange={(e) =>
                      setData({ ...data, garage_use: e.target.value })
                    }
                    label={"Uso garaje"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    value={data.other_use}
                    placeholder={"m2"}
                    onChange={(e) =>
                      setData({ ...data, other_use: e.target.value })
                    }
                    label={"Otros usos"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    value={data.number_homes}
                    placeholder={"nº"}
                    onChange={(e) =>
                      setData({ ...data, number_homes: e.target.value })
                    }
                    label={"Número de viviendas"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    placeholder={"nº"}
                    value={data.number_floors}
                    onChange={(e) =>
                      setData({ ...data, number_floors: e.target.value })
                    }
                    label={"Número de plantas"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    placeholder={"nº"}
                    value={data.underground}
                    onChange={(e) =>
                      setData({ ...data, underground: e.target.value })
                    }
                    label={"Bajo rasante"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    placeholder={"nº"}
                    value={data.overground}
                    onChange={(e) =>
                      setData({ ...data, overground: e.target.value })
                    }
                    label={"Sobre rasante"}
                  />
                </Grid>
              </Grid>
            </ThemeCard>

            {/* step3------------------------------------------------ */}
            <Box
              sx={{
                height: 80,
                width: 40,
                borderRight: "2px dashed #4211c6",
              }}
            />
            <ThemeCard sx={{ pb: 6 }}>
              <Typography
                sx={{ fontWeight: 700, mb: 4 }}
                variant="h6"
                color="#4211c6"
                component="div"
              >
                <Box
                  sx={{
                    backgroundColor: "#4211c6",
                    color: "white",
                    borderRadius: "50%",
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    lineHeight: "20px",
                    fontSize: "15px",
                    mr: 1,
                  }}
                  component="span"
                >
                  3
                </Box>
                Grado de complejidad
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <ThemeSelect
                    value={data.num_facades}
                    onChange={(e) =>
                      setData({ ...data, num_facades: e.target.value })
                    }
                    label={"Número de fachadas"}
                  >
                    <MenuItem disabled value="">
                      Selecciona el número de fachadas
                    </MenuItem>
                    <MenuItem value={1}>1 fachada</MenuItem>
                    <MenuItem value={2}>2 fachadas</MenuItem>
                    <MenuItem value={3}>3 o más fachadas</MenuItem>
                  </ThemeSelect>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeSelect
                    value={data.topography}
                    onChange={(e) =>
                      setData({ ...data, topography: e.target.value })
                    }
                    label={"Topografía"}
                  >
                    <MenuItem disabled value="">
                      Selecciona la tipología
                    </MenuItem>
                    <MenuItem value={1}>Sencilla</MenuItem>
                    <MenuItem value={2}>Compleja</MenuItem>
                  </ThemeSelect>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeSelect
                    value={data.foundation}
                    onChange={(e) =>
                      setData({ ...data, foundation: e.target.value })
                    }
                    label={"Cimentación"}
                  >
                    <MenuItem disabled value="">
                      Tipo de cimentación
                    </MenuItem>
                    <MenuItem value={1}>Sencilla</MenuItem>
                    <MenuItem value={2}>Compleja</MenuItem>
                  </ThemeSelect>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeSelect
                    value={data.structure}
                    onChange={(e) =>
                      setData({ ...data, structure: e.target.value })
                    }
                    label={"Estructura"}
                  >
                    <MenuItem disabled value="">
                      Tipo la estructura
                    </MenuItem>
                    <MenuItem value={1}>Sencilla</MenuItem>
                    <MenuItem value={2}>Compleja</MenuItem>
                    <MenuItem value={3}>Muy Compleja</MenuItem>
                  </ThemeSelect>
                </Grid>
              </Grid>
            </ThemeCard>

            {/* step4------------------------------------------------ */}
            <Box
              sx={{
                height: 80,
                width: 40,
                borderRight: "2px dashed #4211c6",
              }}
            />
            <ThemeCard sx={{ pb: 6, mb: 4 }}>
              <Typography
                sx={{ fontWeight: 700, mb: 4 }}
                variant="h6"
                color="#4211c6"
                component="div"
              >
                <Box
                  sx={{
                    backgroundColor: "#4211c6",
                    color: "white",
                    borderRadius: "50%",
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    lineHeight: "20px",
                    fontSize: "15px",
                    mr: 1,
                  }}
                  component="span"
                >
                  4
                </Box>
                Dedicación
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    placeholder={"Horas"}
                    value={data.project_hours}
                    onChange={(e) =>
                      setData({ ...data, project_hours: e.target.value })
                    }
                    label={"Proyecto básico:"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    placeholder={"Horas"}
                    value={data.execution_hours}
                    onChange={(e) =>
                      setData({ ...data, execution_hours: e.target.value })
                    }
                    label={"Proyecto de ejecución:"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    placeholder={"Horas"}
                    value={data.management_hours}
                    onChange={(e) =>
                      setData({ ...data, management_hours: e.target.value })
                    }
                    label={"Dirección de obra:"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ThemeInput
                    type="number"
                    value={data.travel_cost}
                    onChange={(e) =>
                      setData({ ...data, travel_cost: e.target.value })
                    }
                    adornment={<EuroIcon sx={{ fontSize: "14px" }} />}
                    label={"Coste total por desplazamiento (combustible, horas x coste, taxi, …)"}
                  />
                </Grid>
              </Grid>
            </ThemeCard>

            <Box sx={{ mb: 6 }}>
              <Button
                onClick={handleSave}
                sx={{ backgroundColor: "#4211c6", borderRadius: "5px" }}
                variant="contained"
              >
                <Typography sx={{ fontSize: "15px", textTransform: "none" }}>
                  Calcular honorarios
                </Typography>
              </Button>
            </Box>

            {/* Register Card------------------------------------------------ */}
            <Card sx={{ backgroundColor: "#4211c6", borderRadius: "10px" }}>
              <Grid container spacing={"50px"}>
                <Grid
                  sx={{ display: { md: "block", xs: "none" } }}
                  item
                  xs={12}
                  md={5}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{ width: "100%" }}
                      component={"img"}
                      src="/images/image2.png"
                      alt=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: { md: "flex-start", xs: "center" },
                      flexDirection: "column",
                      height: "100%",
                      position: "relative",
                      p: { md: 0, xs: 2 },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "25px",
                        color: "white",
                        textAlign: { md: "left", xs: "center" },
                      }}
                    >
                      Si quieres trabajar con más herramientas de este tipo,
                      registrate en Viviendea
                    </Typography>
                    <Button
                      sx={{
                        backgroundColor: "#dc616e",
                        width: { md: "220px", xs: "90%" },
                        borderRadius: "5px",
                        my: 2,
                        "&:hover": {
                          backgroundColor: "#b94a55", // Adjust hover background color
                        },
                      }}
                      variant="a"
                      href="https://viviendea.com/register"
                    >
                      <Typography
                        sx={{ fontSize: "15px", textTransform: "none",color:'#fff' }}
                       
                      >
                        Registrarme
                      </Typography>
                      <Box
                        sx={{
                          display: { md: "none", xs: "block" },
                          position: "absolute",
                          top: "-36px",
                          right: "-25px",
                          width: "50px"
                        }}
                        component={"img"}
                        src="/images/image5.svg"
                        alt=""
                      />
                    </Button>
                    <Box
                      sx={{
                        display: { md: "block", xs: "none" },
                        position: "absolute",
                        right: "60px",
                        bottom: "65px",
                        width: "180px"
                      }}
                      component={"img"}
                      src="/images/linea-7.svg"
                      alt=""
                    />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Box>
      </Box>

      <FeesDialog open={open} handleClose={handleClose} data={response} />
      <ToastContainer />
      {loading && <Loader />}
    </>
  );
}

export default App;
