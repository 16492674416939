import * as React from "react";
import Button from "@mui/material/Button";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

export default function FeesDialog({ open, handleClose, data }) {
  console.log("data: ", data);
  return (
    <React.Fragment>
      <Dialog
        sx={{
          borderRadius: "10px",
          "& .MuiDialog-container": {
            backgroundColor: { xs: "#fff", md: "transparent" },
            fontFamily: "'Roboto','Helvetica','Arial','sans-serif'"
          },
          "& .MuiPaper-root": {
            borderRadius: "10px",
            marginX: { md: "32px", xs: "10px" },
            marginTop: { md: "32px", xs: "10px" },
            marginBottom: { md: "16px", xs: "10px" },
          },
        }}
        maxWidth
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            width: { md: "1108px", xs: "100%" },
            backgroundColor: "#4211c6",
            overflow: { md: "hidden", xs: "auto" },
            paddingX: { md: 2, xs: 0 },
            py: { md: 2, xs: 2 },
          }}
        >
          <Typography
            sx={{
              display: { md: "none", xs: "block" },
              fontWeight: 700,
              m: 1,
              color: "#fff",
            }}
            variant="h5"
          >
            Tus honorarios son...
          </Typography>
          <Card sx={{ px: { md: 4, xs: 2 }, py: 2, mb: 4 }}>
            <Typography
              sx={{
                color: "black",
                fontSize: "18px",
                display: { md: "block", xs: "none" },
                fontWeight: 700,
                mb: 1,
              }}
              variant="subtitle1"
            >
              Tus honorarios son...
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: { md: 650, xs: "100%" } }}
                aria-label="simple table"
              >
                <TableBody
                  sx={{
                    "&:last-child th": {
                      border: 0,
                      padding: 0.5,
                      fontWeight: 600,
                      fontSize: "13px",
                      // color: "black",
                      letterSpacing: "normal",
                      width: "110%"
                    },
                    "&:last-child td": {
                      border: 0,
                      padding: 0.5,
                      fontWeight: 800,
                      letterSpacing: "normal",
                      fontSize: "15px",
                      // color: "black",
                    }
                  }}
                >
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ display: 'flex' }}>
                      <Box>Esto es lo que deberias cobrar por el proyecto</Box>
                      <Box sx={{
                        borderBottom: '2px dotted #000',
                        width: 'calc(100% - 317px)',
                        marginLeft: 0.7,
                        height: 14
                      }} ></Box>
                    </TableCell>
                    <TableCell align="right" sx={{
                      minWidth: '100px'
                    }}> {data.number1 ? data.number1 : ' - '} € </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ display: 'flex' }}>

                      <Box>Este sería tu coste hora si nos hicieras caso</Box>
                      <Box sx={{
                        borderBottom: '2px dotted #000',
                        width: 'calc(100% - 304px)',
                        marginLeft: 0.7,
                        height: 14
                      }} ></Box>
                    </TableCell>
                    <TableCell align="right" sx={{
                      minWidth: '100px'
                    }}>{data.number2 ? data.number2 : ' - '} €/h </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ display: 'flex' }}>

                      <Box>Pero, tu coste real esta siendo de</Box>
                      <Box sx={{
                        borderBottom: '2px dotted #000',
                        width: 'calc(100% - 236px)',
                        marginLeft: 0.7,
                        height: 14
                      }} ></Box>
                    </TableCell>
                    <TableCell align="right" sx={{
                      minWidth: '100px'
                    }}>{data.number3 ? data.number3 : ' - '} €/h cada mes</TableCell>
                  </TableRow>
                  <TableRow sx={{
                    borderTop: {
                      xs: "2px solid black !important",
                      md: "none !important",
                    }
                  }}>
                    <TableCell component="th" scope="row"
                      sx={{
                        display: 'flex',
                        fontSize: { md: "12px", xs: "20px" },
                        // marginTop: "20px !important",
                        // paddingRight: "100px !important",
                        paddingTop: {
                          md: "20px !important",
                          xs: "10px !important",
                        }, textAlign: {
                          xs: "left",
                          md: "right",
                        },
                      }}>

                      <Box sx={{
                        display: { md: 'flex', xs: "none" },
                        width: 'calc(100% - 60px)',
                        marginLeft: 0.7,
                        height: 14
                      }} ></Box>
                      <Box>Total</Box>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: data.number4 < 0 ? "red !important" : data.number4 === 0 ? "black !important" : "#69aa64 !important",
                        fontSize: { md: "", xs: "20px" },
                        minWidth: '100px',
                        marginTop: "20px !important",
                        paddingTop: {
                          md: "20px !important",
                          xs: "10px !important",
                        },
                      }}
                    >{data.number4 ? data.number4 : ' - '} €/h</TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell
                      // sx={{
                      //   fontSize: { md: "12px", xs: "20px" },
                      //   marginTop: "20px !important",
                      //   // paddingRight: "100px !important",
                      //   paddingTop: {
                      //     md: "20px !important",
                      //     xs: "10px !important",
                      //   },
                      //   borderTop: {
                      //     xs: "2px solid black !important",
                      //     md: "none !important",
                      //   },
                      //   textAlign: {
                      //     xs: "left",
                      //     md: "right",
                      //   },
                      // }}
                      align="right"
                    >
                      Total
                    </TableCell>
                    <TableCell
                      sx={{
                        color: data.number4 < 0 ? "red !important" : data.number4 === 0 ? "black !important" : "#69aa64 !important",
                        fontSize: { md: "", xs: "20px" },
                        marginTop: "20px !important",
                        paddingTop: {
                          md: "20px !important",
                          xs: "10px !important",
                        },
                        borderTop: {
                          xs: "2px solid black !important",
                          md: "none !important",
                        },
                      }}
                      align="right"
                    >
                      {data.number4} €/h
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: 6,
              }}
            >
              <Box
                sx={{
                  display: { md: "flex", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  width: "650px",
                  gap: 2,
                }}
              >
                <Box
                  sx={{ width: "35px" }}
                  component={"img"}
                  src="/images/info.png"
                  alt=""
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: { md: '20px', xs: '18px' },
                    lineHeight: { md: '27px', xs: '23px' }
                  }}
                  variant="h6"
                >
                  {/* Si cobras lo que te decimos{" "}
                  <span style={{ color: "#69aa64" }}>ganas 3€/h</span>, es
                  decir, 528€ al mes. Necesitas{" "}
                  <span style={{ color: "#dc616e" }}>
                    6 proyectos a la vez para cobrar el salario mínimo
                    interprofesional.
                  </span> */}
                  {data.msg}
                </Typography>
              </Box>
            </Box>
          </Card>
          <Box sx={{ maxWidth: 900, px: { md: 4, xs: 2 }, py: { xs: 2, md: 0 }, mb: 3 }}>
            <Grid container>
              <Grid
                sx={{ display: { md: "block", xs: "none" } }}
                item
                xs={12}
                md={4}
              >
                <Box
                  sx={{
                    width: "300px",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                  }}
                  component={"img"}
                  src="/images/image4.png"
                  alt=""
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: { md: "flex-start", xs: "center" },
                    flexDirection: "column",
                    height: "100%",
                    gap: "20px",
                    position: "relative",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: { md: "25px", xs: "20px" },
                      color: "white",
                      textAlign: { md: "left", xs: "center" },
                    }}
                  >
                    Si quieres trabajar con más herramientras de este tipo,
                    registrate en Viviendea
                  </Typography>
                  <Button
                    sx={{
                      backgroundColor: "#dc616e",
                      width: { md: "220px", xs: "90%" },
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "#b94a55",
                      },
                    }}
                    variant="a"
                    href="https://viviendea.com/register"
                  >
                    <Box
                      sx={{ fontSize: "15px", textTransform: "none", color: '#fff' }}
                    >
                      Registrarme
                    </Box>
                    <Box
                      sx={{
                        display: { md: "none", xs: "block" },
                        position: "absolute",
                        top: "-36px",
                        right: "-25px",
                        width: "50px"
                      }}
                      component={"img"}
                      src="/images/image5.svg"
                      alt=""
                    />
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: { md: "block", xs: "none" },
                    position: "absolute",
                    right: "200px",
                    bottom: "30px",
                    width: "150px"
                  }}
                  component={"img"}
                  src="/images/linea-7.svg"
                  alt=""
                />
              </Grid>
            </Grid>
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", top: { md: 5, xs: 15 }, right: { md: 5, xs: 0 } }}
            >
              <CancelRoundedIcon
                sx={{
                  fontSize: "30px",
                  color: { md: "white", xs: "#d5d5d5" },
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
