export const SaveData = async (data) => {
  console.log("data: ", data);
  try {
    const response = await fetch(
      "https://europe-west3-viviendea.cloudfunctions.net/landing_projectCost",
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    return { status: true, data: responseData };
  } catch (error) {
    console.error("Error:", error);
    return { error: error.message }; // Return an object with error message
  }
};
