import {
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

export function ThemeInput({
  value,
  onChange,
  label,
  adornment,
  type,
  placeholder,
}) {
  return (
    <>
      <InputLabel sx={{ fontSize: "13px", color: "#000", mb: 0.5 }}>
        {label}
      </InputLabel>
      <TextField
        size="small"
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        InputProps={
          adornment
            ? {
                startAdornment: (
                  <InputAdornment position="start">{adornment}</InputAdornment>
                ),
              }
            : {}
        }
        fullWidth
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "10px",
          },
        }}
      />
    </>
  );
}

export function ThemeSelect({ value, onChange, label, children }) {
  return (
    <>
      <InputLabel sx={{ fontSize: "13px", color: "#000", mb: 0.5 }}>
        {label}
      </InputLabel>
      <FormControl fullWidth>
        <Select
          size="small"
          value={value}
          displayEmpty
          onChange={onChange}
          fullWidth
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "10px",
            },
          }}
        >
          {children}
        </Select>
      </FormControl>
    </>
  );
}
