import { Box } from "@mui/material";
import React from "react";

export default function ThemeCard({ children, sx }) {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        px: { md: 6, xs: 2 },
        py: 3,
        border: "2px solid #9a81e0",
        borderRadius: "10px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
